<template>
  <div class="wrapper">
    <div class="wrap-head">
      <div class="wrap-bg-box">
        <img class="wrap-bg" src="../../../assets/images/wangshangdai/bg.jpg" alt="">
      </div>
      <div class="wrap-list">
        <div class="wrap-card" style="margin:0">
          <div class="card-head">
            <div class="hd">
              <span class="icon icon-left"></span><span class="card-hd-text">云卡e付通是什么</span> <span
              class="icon icon-right"></span></div>
          </div>
          <div class="card-content">
            云卡，作为国内首家O2O卡车的全国维修联盟服务互联网平台，通过整合线下门店形成连锁联盟，
            建立起全国范围内的卡车维修服务体系，为物流企业提供一体化服务。全国已经有超过1000家的加盟合作门店，
            为物流车辆提供全国范围的统一标准、统一流程、统一价格、统一结算的维修保养服务保障。
          </div>

        </div>
      </div>
    </div>
    <div class="wrap-card" style="margin-top:2rem">
      <div class="card-head">
        <div class="hd">
          <span class="icon icon-left"></span><span class="card-hd-text">云卡e付通解决什么问题</span> <span
          class="icon icon-right"></span></div>
      </div>
      <div class="card-content">
        <span class="title">我们帮助车队解决以下三个问题：</span>
        <div class="text"><span style="font-weight: bold;">一、</span>原来车辆在外地维修中基本都是由车队提供备用金的形式，这样其实是车队提前准备相关资金，无法挂账，现金流压力大。我们通过此专项基金可为车队提供全国维修挂账，车辆维修完成后签字走人；
        </div>
        <div class="text"><span style="font-weight: bold">二、</span>解决原有在维修过程中的维修不透明的，维修后不可追溯的问题。在云卡平台的维修都完整清晰的维修过程记录，使老板在家就能了解维修中全部细节和维修内容。而且后期如果有维修质量问题，云卡平台都可追溯到原维修单位，保障
          车辆维修的全国质保和索赔问题；
        </div>
        <div class="text"><span style="font-weight: bold">三、</span>原外地几乎很难获得维修发票的，而在云卡平台内的维修门店维修的所有费用都有云卡向物流企业开具13%的维修专用发票。每个月云卡会根据系统记录里本公司发生的所有维修费用统一向物流公司开具专用发票；</div>
      </div>

    </div>
    <div class="wrap-card">
      <div class="card-head">
        <div class="hd">
          <span class="icon icon-left"></span><span class="card-hd-text">为什么通过云卡申请</span> <span
          class="icon icon-right"></span></div>
      </div>
      <div class="card-content">
        <div class="text">因为此基金为云卡和支付宝正式合作的渠道，此授信额度为专项基金是用于车队在支付车辆维修费用时使用的金融授信。通过云卡申请的企业有以下两个重大优惠：
        </div>
        <div class="text"><span style="font-weight: bold">一、</span>通过云卡申请的网商贷，在企业原有的额度基础还可以再提高相应的额度；</div>
        <div class="text"><span style="font-weight: bold">二、</span>通过云卡申请的网商贷，在原网商贷的利息标准上还可以享受利息打85折的优惠；</div>
      </div>

    </div>
    <div class="wrap-card">
      <div class="card-head">
        <div class="hd">
          <span class="icon icon-left"></span><span class="card-hd-text">e付通办理流程</span> <span
          class="icon icon-right"></span></div>
      </div>
      <div class="card-content">
        <div class="card-step">
          <div class="step">
            <div class="step-top">
              <img class="step-top-img" src="../../../assets/images/wangshangdai/1.png" alt="">
            </div>
            <div class="step-bottom">提交资料</div>
          </div>
          <div class="line"><span class="icon line-icon"></span></div>
          <div class="step">
            <div class="step-top">
              <img class="step-top-img" src="../../../assets/images/wangshangdai/2.png" alt="">
            </div>
            <div class="step-bottom">支付宝审核</div>
          </div>
          <div class="line"><span class="icon line-icon"></span></div>
          <div class="step">
            <div class="step-top">
              <img class="step-top-img" src="../../../assets/images/wangshangdai/3.png" alt="">
            </div>
            <div class="step-bottom">开通服务</div>
          </div>
        </div>
      </div>

      <div class="btn" style="background-color:#FF7900;margin-left: 1.7rem"  @click="goAbout">
        了解云卡
      </div>
    </div>
    <div class="wrap-card">
      <div class="card-head">
        <div class="hd"><span class="icon icon-left"></span><span class="card-hd-text">提交资料</span> <span
          class="icon icon-right"></span></div>
      </div>
      <div class="card-content">
        <div class="input_box">
          <span>企业名称：</span>
          <input class="input-content" title="" name="company" placeholder="请输入企业名称" v-model="form.company" required></input>
        </div>
        <div class="input_box">
          <span>统一信用代码：</span>
          <input class="input-content" title="" name="unicode" placeholder="请输入统一信用代码" v-model="form.unicode"
                 required></input>
        </div>
        <div class="input_box">
          <span>企业法人代表：</span>
          <input class="input-content" title="" name="legal" placeholder="请输入企业法人代表" v-model="form.legal"
                 required></input>
        </div>
        <div class="input_box">
          <span>联系人：</span>
          <input class="input-content" title="" name="linkUser" placeholder="请输入联系人" v-model="form.linkUser"
                 required></input>
        </div>
        <div class="input_box">
          <span>手机：</span>
          <input class="input-content" title="" name="linkTel" placeholder="请输入手机号" v-model="form.linkTel" required></input>
        </div>
        <div class="input_box" id="code">
          <span>验证码：</span>
          <input class="input-content" title="" name="code" placeholder="请输入验证码" v-model="form.code" required></input>
          <div class="code-btn" @click="get_captcha">获取验证码</div>
        </div>
      </div>
      <div class="btn" style="background-color:#FF7900;margin-left: 1.7rem" @click="submit">
        提交
      </div>
    </div>
    <div class="footer">
      <img class="footer-img" src="../../../assets/images/wangshangdai/footer.png" alt="">
    </div>
  </div>
</template>
<script>
  import {isvalidPhone,isCompanyUnicode,getStrLen} from '@/utils/validate'
  import{applySendCode, apply} from '@/interface/api/wangshangdai/customer/index'
  export default {
    data: function () {
      return {
        show: true,
        getCodeText: '获取验证码',
        count: 60,
        timer: null,
        getCode: {
          txt: '获取验证码',
          disabled: false,
        },
        form: {
          company: '',
          unicode:'',
          staffCode:'',
          linkUser: '',
          linkTel: "",
          legal: '',
          code:''
        },
      }
    },
    watch: {
      timer: function (val) {
        if (val == null) {
          //  监听timer变化，移除不可点击样式
        }
      }
    },
    created() {
      let scode = this.$route.query.scode
      if (scode) {
        this.form.staffCode = scode
      }
    },
    methods: {
      showDetail() {
        this.$router.push('/detail');
      },
      goAbout() {
         this.$router.push('/about')
      },
      get_captcha() {
        if (isvalidPhone(this.form.linkTel)) {
          const data = {
            tel: this.form.linkTel
          }
          applySendCode(data)
            .then(response => {
              if (response.status === true) {
                // this.$vux.toast.text('短信发送成功，请注意查收', 'middle')
                this.$layer.toast({
                  content: '短信发送成功，请注意查收',
                })

                if (this.timer == null) {
                }
                if (!this.timer) {
                  this.count = 60;
                  this.show = false;
                  // 将鼠标设置为不可点击状态
                  this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= 60) {
                      this.count--
                      this.getCodeText = this.count + 's';
                    } else {
                      this.show = true
                      clearInterval(this.timer)
                      this.timer = null
                      this.getCodeText = '获取验证码'
                    }
                  }, 1000)
                }
              } else {
                this.$layer.toast({
                  content: '当前手机号已被绑定',
                })

              }
            }).catch(() => {
          })
        } else {
          this.$layer.toast({
            content: '请填写正确的手机号',
          })
          // this.$vux.toast.text('请填写正确的手机号', 'middle')
        }


      },
      submit() {
        if (getStrLen(this.form.company)<5) {
          this.$layer.toast({
            content: '企业名称长度至少五个字符!',
          })
          return
        } else if (!isCompanyUnicode(this.form.unicode)) {
          this.$layer.toast({
            content: '请填写正确的企业代码!',
          })
          return
        }  else if (getStrLen(this.form.legal)<2) {
          this.$layer.toast({
            content: '企业法人名称长度至少两个字符!',
          })
          return
        }else if (getStrLen(this.form.linkUser)<2) {
          this.$layer.toast({
            content: '联系人名称长度至少两个字符!',
          })
          return
        } else if (!isvalidPhone(this.form.linkTel)) {
          this.$layer.toast({
            content: '请填写正确的手机号!',
          })
          return
        } else if (this.form.code == '') {
          this.$layer.toast({
            content: '请填写正确的验证码!',
          })
          return
        }
        apply(this.form)
          .then(response => {
            if(response.status === true){
              this.$layer.toast({
                content: '已提交成功!',
              })
              this.$router.push('/result')
            }else{
              this.$layer.toast({
                content: response.msg,
              })
            }
          }).catch(() => {
        })
      },
    }
  }
</script>
<style scoped lang="less">
  // input初始化样式
  input {
    background: none;
    outline: none;
    border: none;
    font-size: 16px;
  }

  //按钮样式
  .btn-box {
    position: absolute;
    top: 55rem;
    left: 2.58rem;
  }

  .btn {
    font-size: 1.42rem;
    font-weight: bold;
    color: white;
    background-color: #FDA600;
    width: 24.83rem;
    height: 3.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.88rem;
  }

  //开始
  .wrapper {
    color:#343434;
    background-color: #F7F7F7;
    font-size: 1.17rem;
    width: 31.25rem;
    height: auto;

    //背景头
    .wrap-head {
      .wrap-bg-box {
        position: relative;
        width: 100%;

        .wrap-bg {
          width: 31.25rem;
          height: 60rem;
        }
      }

      .wrap-list {
        width: 28.75rem;
        height: auto;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 0.67rem;
        margin-right: 1.25rem;
        margin-left: 1.25rem;
        position: absolute;
        top: 37.25rem;
        left: 0;

        .wrap-list-item {
          padding: 0 1.33rem;
          margin-top: 2.4rem;
          display: flex;
          justify-content: space-between;

          .item {
            display: flex;
            font-size: 1.25rem;
            font-weight: bold;

            .item-lt {
              .lt-img {
                width: 3.5rem;
                height: 3.5rem;
              }
            }

            .item-rt {
              margin-left: 0.63rem;

              .rt-top-text {
                color: #555555;
              }

              .rt-bottom-text {
                color: #FF6600;
              }
            }
          }
        }
      }
    }

    //底部卡片
    .wrap-card {
      background-image: linear-gradient(180deg, #EBF6FF 0%, #FFFFFF 11%);
      font-size: 1.17rem;
      margin: 0 1.25rem 1.25rem 1.25rem;
      padding-bottom: 2rem;
      border: 0.2rem solid #F6F6F6;
      border-radius: 0.67rem;
      box-shadow: 0 0.5rem 0.83rem 0 rgba(150, 150, 150, 0.10), inset 0 0.08rem 0 0 #FFFFFF;

      .card-head {
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
        background-image: linear-gradient(#EBF6FF, #FFFFFF);
        padding:1.5rem 0 0 0;
        .hd {
          .icon {
            display: inline-block;
            width: 5rem;
            height: 0.25rem;
            margin-bottom: 0.5rem;
          }

          .icon-right {
            background-image: url('../../../assets/images/wangshangdai/line_right.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          .icon-left {
            background-image: url('../../../assets/images/wangshangdai/line_left.png');
            background-repeat: no-repeat;
            background-size: 95% 100%;
          }

          .card-hd-text {
            font-size: 1.5rem;
            font-weight: bold;
          }
        }
      }

      .card-content {
        padding: 1.92rem;
        .title {
          font-size: 1.33rem;
          font-weight: bold;
          color: #1593F8;
        }
        .text{
          margin-top:0.3rem;
        }

        #code {
          display: flex;
          align-items: center;
          border-bottom: 0.08rem solid #F0F0F0;

          .input-content {
            width: 40%;
          }

          .code-btn {
            margin-left: 1.5rem;
            color: white;
            width: 7.67rem;
            heigh: 3rem;
            line-height: 3rem;
            text-align: center;
            border-radius: 0.5rem;
            background-color: #FF7B04;
          }
        }

        .input_box {
          display: flex;
          align-items: center;
          padding: 2rem 0;
          border-bottom: 1px solid #F5F5F5;

          .input-content {
            width: 60%;
          }

          .code-btn {
            color: #3479AF;
            width: 7.67rem;
            heigh: 3rem;
            line-height: 3rem;
            text-align: center;
            border-radius: 0.5rem;
            background-color: #D7F3FF;
          }

        }

        .card-step {
          display: flex;
          justify-content: space-between;

          .line {
            margin-top: 0.3rem;

            .icon {
              display: inline-block;
              width: 4rem;
              height: 0.08rem;
              background-color: #D8D8D8;
            }

            .line-icon {
              background-image: url('../../../assets/images/line.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
          }

          .step {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .step-top-img {
              width: 3.5rem;
              height: 3.5rem;
            }
          }
        }
      }

    }

    .footer {
      padding: 2rem 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .footer-img {
        width: 25.17rem;
        height: auto
      }
    }

  }

</style>
